import { useCallback } from 'react'

import { TableDataCall } from 'components/Table/api'
import { TableGenericRequest, TableResponse } from 'components/Table/types'
import { fetchAssignUsersList } from 'features/account/api'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { DropDownEnvelope } from 'utils/types/DropDownEnvelope'
import { DropDownResponse } from 'utils/types/DropDownResponse'
import { Envelope } from 'utils/types/Envelope'

import {
  importReferral,
  getActivityLogs,
  saveNewParentOrChildReferral,
  getChildReferralDetail,
  getParentReferralDetail,
  saveNewAttachments,
  getReferralSourcePdf,
  getAttachmentDoc,
  getQuestionView,
  submitQuestion,
  getQuestionAnswers,
  setHoldReferral,
  setLostReferral,
  getReferralForGridview,
  getRefLists,
  getPatientMatch,
  savePendingPatient,
  childReferralStatusUpdate,
  childReferralApproveReject,
  referralManualTriggering,
  getCommentLogs,
  getSexOffenderList,
  sexOffenderMatch,
  getRefDenialReasons,
  getPayerLists,
  waystarMatchRequest,
  wayStarSubmit,
  deleteReferral,
  deleteParentReferral,
  wayStarValidate,
  updateChildReferral,
  updateParentReferral,
  parentReferralStatusUpdate,
  parentReferralApproveReject,
  parentReferralFacilities,
  getRoutes,
  getReferralStatus,
  waystarDelete,
  getIntegrationFeatures,
  sendOutBoundCommunication,
  getMessagesFromSource,
  childReferralStatusRevise,
  getTodoList,
  updateTaskListStatus,
  getPccPatientDetails,
  getMostRecentDenialReason,
  getIntegrationSourceStatus,
  getInsuranceDetailsByPatientId,
  callHoldReasons,
  callLostReasons,
  unLockReferral,
  addMappedCommunity,
  updateMappedCommunity,
  getCommunitiesForUnmatched,
  getMappedCommunities,
  getUnmappedCommunities,
  callReviseToClinicalReview,
  referralRegeneratePDF,
  updateClinicalReviewStatus,
  updateClinicalReviewStatusChild,
  getFacilitiesForClinicalReview,
  getPverifyPayerLists,
  pVerifyMatchRequest,
  pVerifyValidate,
  pVerifySubmit,
  pVerifyDeletePayer,
  assignedUsersList,
  deleteLeadAgent,
  addReferralUsers,
  assignedUsersCount,
  confirmReadAlert,
  fetchReferralUpdates,
  getAllSourceHospitals,
} from '../api'
import {
  getSelectedKeyword,
  referralActions,
  selectReferralDetail,
  selectReferralList,
  getAllKeyword,
  getIsQuestionAnswersLoaded,
  getCategoryDetailList,
  getSelectedReferral,
  selectedParentReferral,
  getSelectedListTab,
  getReferralListRoot,
  getRouteList,
  getParentFilterItems,
  getChildFilterItems,
  getRefreshForSourceMessageData,
  getSourceMessageTabToSet,
  getReferralListInputs,
  getIsTaskListPending,
  getOrganizationList,
  waystarValidationChanged,
  getHoldReasons,
  getLostReasons,
  getDenialReasonList,
  getReferralGridViewList,
  getFacilityListForParentFilter,
  getFacilityListForChildFilter,
  getInsuranceProvider,
  getPVerifyLinks,
  pVerifyValidationChanged,
  getSelectedParentTab,
} from '../store'
import {
  ActivityLogsResponse,
  Categories,
  QuestionRequest,
  Referral,
  ReferralList,
  AnswerList,
  KeywordDetail,
  HoldReferral,
  LostReferral,
  ReferralsGidview,
  CardReferral,
  CardResponse,
  PendingPatient,
  ReferralApproveRejectRequest,
  SexOffenderMatchBody,
  WaystarMatchRequest,
  ParentReferralApproveReject,
  QuestionAnswer,
  ReferralListType,
  OutboundMessageRequest,
  ReferralFilters,
  MessagesFromSourceRequest,
  TaskListRequest,
  UpdateTaskListStatus,
  ReferralListTypeInputs,
  ReferralListInputsParams,
  ChildReferralFilters,
  UnLockReferralData,
  MappedCommunitiesRequest,
  CommunitiesForUnmatchedRequest,
  AddMappedCommunityRequest,
  SubmitClinicalReviewParentDTO,
  SubmitClinicalReviewChildDTO,
  DenialResponse,
  CategoryResponse,
  PVerifyLinks,
  PVerifyMatchRequest,
  PVerifySubmitObject,
  AssignLeads,
  AssignListResponse,
  AssignedUsersReq,
  DeleteAssignedUserReq,
  AssignedUserResponse,
  AddUsersReq,
  ActivityLogsRequest,
  ConfirmReadAlertRequest,
  InsuranceDetailReq,
  ReferralUpdatesResData,
} from '../types'

export type ReferralsServiceOperators = {
  importReferralFile: (data: FormData) => Promise<Envelope>
  getReferralList: (data: TableGenericRequest, endPoint: string) => Promise<TableResponse>
  getActivityLogList: (data: ActivityLogsRequest) => Promise<ActivityLogsResponse>
  addNewParentOrChildReferral: (data: Referral) => Promise<Envelope>
  addNewAttachment: (data: FormData) => Promise<Envelope>
  updateChildReferral: (data: Referral) => Promise<Envelope>
  updateParentReferral: (data: Referral) => Promise<Envelope>
  getChildReferralDetail: (id: number) => Promise<Envelope>
  getParentReferralDetail: (id: number) => Promise<Envelope>
  putReferralList: (data: object) => void
  referralList: ReferralList
  putReferralDetail: (data: Referral) => void
  referralDetail: Referral
  setSelectedKeyword: (keywords?: KeywordDetail[]) => void
  getSelectedKeyword: KeywordDetail[]
  setAllKeyword: (keywords?: KeywordDetail[]) => void
  getAllKeyword: KeywordDetail[]
  setParentFilterItems: (filters?: ReferralFilters) => void
  setChildFilterItems: (filters?: ChildReferralFilters) => void
  getParentFilterItems: ReferralFilters
  getChildFilterItems: ChildReferralFilters
  setIsQuestionAnswersLoaded: (isloaded?: boolean) => void
  getIsQuestionAnswersLoaded: boolean
  getReferralSourcePdf: (patientId: number, isHighlightedPdf: boolean) => Promise<Envelope>
  getAttachmentDoc: (id: number) => Promise<Envelope>
  getQuestionView: (
    data: QuestionRequest,
  ) => Promise<{ data: { categoriesDetailDTOs: Categories[] } }>
  saveSubmitQuestion: (data: AnswerList) => Promise<Envelope>
  getQuestionAnswers: (data: QuestionAnswer) => Promise<Envelope>
  holdReferral: (data: HoldReferral) => Promise<Envelope>
  lostReferral: (data: LostReferral) => Promise<Envelope>
  getReferralGridview: (permission: number[]) => Promise<ReferralsGidview>
  getRefList: (data: CardReferral) => Promise<CardResponse>
  getPatientList: (id: number) => Promise<Envelope>
  savePendingRequest: (data: PendingPatient) => Promise<Envelope>
  updateChildReferralStatus: (patientId: number, statusId: number) => Promise<Envelope>
  updateChildReferralApproveReject: (data: ReferralApproveRejectRequest) => Promise<Envelope>
  setReferralTriggering: (data: { patientId: number }) => Promise<Envelope>
  getCommentLogList: (id: number) => Promise<ActivityLogsResponse>
  getSexOffenderLists: (id: number) => Promise<Envelope>
  applySexOffenderMatch: (body: SexOffenderMatchBody) => Promise<Envelope>
  getDenialReasons: () => Promise<ReferralsGidview>
  setCategoryDetails: (categories?: Categories[]) => void
  getCategoryDetails: Categories[]
  setWaystarMatch: (waystarMatch: WaystarMatchRequest) => void
  setPVerifyMatch: (pVerifyMatch: PVerifyMatchRequest) => void
  getPayerList: () => Promise<Envelope>
  getPVerifyPayerList: () => Promise<Envelope>
  waystarMatchRequest: (body: WaystarMatchRequest) => Promise<Envelope>
  pVerifyMatchRequest: (body: PVerifyMatchRequest) => Promise<Envelope>
  waystarPayerDelete: (body: WaystarMatchRequest) => Promise<Envelope>
  pVerifyPayerDelete: (body: PVerifyMatchRequest) => Promise<Envelope>
  waystarSubmit: (patientId: number) => Promise<Envelope>
  pVerifySubmit: (pVerifyObject: PVerifySubmitObject) => Promise<Envelope>
  waystarValidate: (body: WaystarMatchRequest) => Promise<Envelope>
  pVerifyValidate: (body: PVerifyMatchRequest) => Promise<Envelope>
  getSelectedReferral: number
  setSelectedReferral: (id: number) => void
  selectedParentReferral: number
  setSelectedParentReferral: (id: number) => void
  getSelectedListTab: number
  setSelectedListTab: (data: number) => void
  getSelectedParentTab: number
  setSelectedParentTab: (data: number) => void
  deleteReferral: (id: number) => Promise<Envelope>
  deleteParentReferral: (id: number) => Promise<Envelope>
  updateParentReferralStatus: (patientId: number, statusLevel: string) => Promise<Envelope>
  updateParentReferralApproveReject: (data: ParentReferralApproveReject) => Promise<Envelope>
  getParentReferralFacilities: (patientId: number, statusLevel: string) => Promise<Envelope>
  updateClinicalReviewStatus: (data: SubmitClinicalReviewParentDTO) => Promise<Envelope>
  updateClinicalReviewStatusChild: (data: SubmitClinicalReviewChildDTO) => Promise<Envelope>
  getFacilitiesForClinicalReview: (patientId: number) => Promise<Envelope>

  getAllRoutes: () => Promise<DropDownEnvelope>
  setRouteList: (list: DropDownResponse[]) => void
  getRouteList: DropDownResponse[]
  setOrganizationList: (list: DropDownResponse[]) => void
  organizationList: DropDownResponse[]
  getAllReferralStatus: (permission: number[]) => Promise<DropDownEnvelope>
  referralListRoot: ReferralListType
  setReferralListRoot: (type: ReferralListType) => void
  getIntegrationFeatures: (id: number) => Promise<Envelope>
  sendOutBoundCommunication: (data: OutboundMessageRequest) => Promise<Envelope>
  getMessagesFromSource: (requestBody: MessagesFromSourceRequest) => Promise<Envelope>
  getRefreshForSourceMessageData: boolean
  setRefreshForSourceMessageData: (data: boolean) => void
  reviseChildReferralStatus: (patientId: number, statusId: number) => Promise<Envelope>
  reviseToClinicalReview: (patientId: number, statusId: number, note: string) => Promise<Envelope>
  setSourceMessageTabToSet: (tabVal: number) => void
  getSourceMessageTabToSet: number

  getTodoList: (data: TaskListRequest) => Promise<Envelope>
  updateTodoItem: (data: UpdateTaskListStatus) => Promise<Envelope>
  getReferralListInputs: ReferralListTypeInputs
  setReferralListInputs: (type: string, data: ReferralListInputsParams) => void
  getPccPatientDetails: (orgUuid: string, patientId: number) => Promise<Envelope>

  getRecentDenialReason: (data: { patientFacilityId: number }) => Promise<Envelope>
  setIsTaskListPending: (data: boolean) => void

  getIsTaskListPending: boolean
  getIntegrationSourceStatus: (id: number) => Promise<Envelope>
  getInsuranceDetailsByPatientId: (data: InsuranceDetailReq) => Promise<Envelope>
  setWaystarValidationChanged: () => void
  setPVerifyValidationChanged: () => void
  waystarValidationChanged: boolean
  pVerifyValidationChanged: boolean

  getHoldReasons: () => Promise<DropDownEnvelope>
  setHoldReasons: (list: DropDownResponse[]) => void
  holdReasons: DropDownResponse[]
  getLostReasons: () => Promise<DropDownEnvelope>
  setLostReasons: (list: DropDownResponse[]) => void
  lostReasons: DropDownResponse[]
  unlockReferral: (data: UnLockReferralData) => Promise<Envelope>
  unmappedCommunitiesList: (data: string) => Promise<Envelope>
  mappedCommunitiesList: (data: MappedCommunitiesRequest) => Promise<Envelope>
  getCommunitiesForUnmatched: (data: CommunitiesForUnmatchedRequest) => Promise<DropDownEnvelope>
  addMappedCommunity: (data: AddMappedCommunityRequest) => Promise<Envelope>
  updateMappedCommunity: (data: AddMappedCommunityRequest) => Promise<Envelope>
  regeneratePDF: (data: { patientId: number }) => Promise<Envelope>
  setDenialReasonList: (list: DenialResponse[]) => void
  denialReasonList: DenialResponse[]
  setReferralGridViewList: (list: CategoryResponse[]) => void
  referralGridViewList: CategoryResponse[]
  setFacilityListForParentFilter: (list: DropDownResponse[]) => void
  facilityListForParentFilter: DropDownResponse[]
  setFacilityListForChildFilter: (list: DropDownResponse[]) => void
  facilityListForChildFilter: DropDownResponse[]
  insuranceProvider: number
  setInsuranceProvider: (data: number) => void
  pVerifyLinks: PVerifyLinks
  setPVerifyLinks: (data: PVerifyLinks) => void
  getAssignUsersList: (data: AssignLeads) => Promise<AssignListResponse>
  fetchAssignedUsersList: (data: AssignedUsersReq) => Promise<AssignListResponse>
  deleteAssignedRefUser: (data: DeleteAssignedUserReq) => Promise<Envelope>
  addReferralUsers: (data: AddUsersReq) => Promise<AssignedUserResponse>
  getAssignedUsersCount: (data: number) => Promise<Envelope>
  confirmReadAlert: (data: ConfirmReadAlertRequest) => Promise<Envelope>
  getReferralUpdates: (data: number) => Promise<ReferralUpdatesResData>
  setReferralComparisonId: (data: number) => void
  getSourceHospitalList: () => Promise<Envelope>
}

export const useReferralsService = (): Readonly<ReferralsServiceOperators> => {
  const dispatch = useAppDispatch()
  return {
    importReferralFile: useCallback((data: FormData) => importReferral(data), []),

    getReferralList: useCallback(
      (data: TableGenericRequest, endPoint: string) => TableDataCall(data, endPoint),
      [],
    ),

    getActivityLogList: useCallback((data: ActivityLogsRequest) => getActivityLogs(data), []),

    addNewParentOrChildReferral: useCallback(
      (data: Referral) => saveNewParentOrChildReferral(data),
      [],
    ),
    updateChildReferral: useCallback((data: Referral) => updateChildReferral(data), []),
    updateParentReferral: useCallback((data: Referral) => updateParentReferral(data), []),

    getChildReferralDetail: useCallback((id: number) => getChildReferralDetail(id), []),
    getParentReferralDetail: useCallback((id: number) => getParentReferralDetail(id), []),
    putReferralList: useCallback(
      data => {
        dispatch(referralActions.setReferralList(data))
      },
      [dispatch],
    ),
    referralList: useAppSelector(selectReferralList),
    putReferralDetail: useCallback(
      data => {
        dispatch(referralActions.setReferralDetail(data))
      },
      [dispatch],
    ),
    referralDetail: useAppSelector(selectReferralDetail),
    addNewAttachment: useCallback((data: FormData) => saveNewAttachments(data), []),

    setSelectedKeyword: useCallback(keywords => {
      dispatch(referralActions.setSelectedKeyword(keywords))
    }, []),
    getSelectedKeyword: useAppSelector(getSelectedKeyword),
    setAllKeyword: useCallback(keywords => {
      dispatch(referralActions.setAllKeyword(keywords))
    }, []),
    getAllKeyword: useAppSelector(getAllKeyword),
    setParentFilterItems: useCallback(filters => {
      dispatch(referralActions.setParentFilterItems(filters))
    }, []),
    getParentFilterItems: useAppSelector(getParentFilterItems),
    setChildFilterItems: useCallback(filters => {
      dispatch(referralActions.setChildFilterItems(filters))
    }, []),
    getChildFilterItems: useAppSelector(getChildFilterItems),

    setIsQuestionAnswersLoaded: useCallback(isloaded => {
      dispatch(referralActions.setIsQuestionAnswersLoaded(isloaded))
    }, []),
    getIsQuestionAnswersLoaded: useAppSelector(getIsQuestionAnswersLoaded),

    getReferralSourcePdf: useCallback(
      (patientId: number, isHighlightedPdf: boolean) =>
        getReferralSourcePdf(patientId, isHighlightedPdf),
      [],
    ),
    getAttachmentDoc: useCallback((id: number) => getAttachmentDoc(id), []),

    getQuestionView: useCallback((data: QuestionRequest) => getQuestionView(data), []),
    saveSubmitQuestion: useCallback((data: AnswerList) => submitQuestion(data), []),
    getQuestionAnswers: useCallback(data => getQuestionAnswers(data), []),

    holdReferral: useCallback((data: HoldReferral) => setHoldReferral(data), []),
    lostReferral: useCallback((data: LostReferral) => setLostReferral(data), []),

    getReferralGridview: useCallback(
      (permission: number[]) => getReferralForGridview(permission),
      [],
    ),
    getRefList: useCallback((data: CardReferral) => getRefLists(data), []),

    getPatientList: useCallback((id: number) => getPatientMatch(id), []),

    savePendingRequest: useCallback((data: PendingPatient) => savePendingPatient(data), []),
    updateChildReferralStatus: useCallback(
      (patientId: number, statusId: number) => childReferralStatusUpdate(patientId, statusId),
      [],
    ),
    updateChildReferralApproveReject: useCallback(
      (data: ReferralApproveRejectRequest) => childReferralApproveReject(data),
      [],
    ),

    setReferralTriggering: useCallback(
      (data: { patientId: number }) => referralManualTriggering(data),
      [],
    ),

    getCommentLogList: useCallback((id: number) => getCommentLogs(id), []),

    getSexOffenderLists: useCallback(id => getSexOffenderList(id), []),
    applySexOffenderMatch: useCallback(body => sexOffenderMatch(body), []),

    getDenialReasons: useCallback(() => getRefDenialReasons(), []),
    setCategoryDetails: useCallback(categories => {
      dispatch(referralActions.setCategoryDetailList(categories))
    }, []),
    getCategoryDetails: useAppSelector(getCategoryDetailList),

    setWaystarMatch: useCallback(waystarMatch => {
      dispatch(referralActions.setWaystarMatch(waystarMatch))
    }, []),
    setPVerifyMatch: useCallback(pVerifyMatch => {
      dispatch(referralActions.setPVerifyMatch(pVerifyMatch))
    }, []),
    getPayerList: useCallback(() => getPayerLists(), []),
    waystarMatchRequest: useCallback(body => waystarMatchRequest(body), []),
    waystarPayerDelete: useCallback(body => waystarDelete(body), []),
    waystarSubmit: useCallback(patientId => wayStarSubmit(patientId), []),
    waystarValidate: useCallback(body => wayStarValidate(body), []),

    getSelectedReferral: useAppSelector(getSelectedReferral),
    setSelectedReferral: useCallback(id => {
      dispatch(referralActions.setSelectedReferral(id))
    }, []),

    selectedParentReferral: useAppSelector(selectedParentReferral),
    setSelectedParentReferral: useCallback(id => {
      dispatch(referralActions.setSelectedParentReferral(id))
    }, []),

    getSelectedListTab: useAppSelector(getSelectedListTab) ?? 1,
    setSelectedListTab: useCallback(tabId => {
      dispatch(referralActions.setSelectedListTab(tabId))
    }, []),
    getSelectedParentTab: useAppSelector(getSelectedParentTab) ?? 1,
    setSelectedParentTab: useCallback(tabId => {
      dispatch(referralActions.setSelectedParentTab(tabId))
    }, []),
    deleteReferral: useCallback((id: number) => deleteReferral(id), []),
    deleteParentReferral: useCallback((id: number) => deleteParentReferral(id), []),

    updateParentReferralStatus: useCallback(
      (patientId: number, statusLevel: string) =>
        parentReferralStatusUpdate(patientId, statusLevel),
      [],
    ),
    updateParentReferralApproveReject: useCallback(
      (data: ParentReferralApproveReject) => parentReferralApproveReject(data),
      [],
    ),
    getParentReferralFacilities: useCallback(
      (patientId: number, statusLevel: string) => parentReferralFacilities(patientId, statusLevel),
      [],
    ),

    updateClinicalReviewStatus: useCallback(
      (data: SubmitClinicalReviewParentDTO) => updateClinicalReviewStatus(data),
      [],
    ),
    updateClinicalReviewStatusChild: useCallback(
      (data: SubmitClinicalReviewChildDTO) => updateClinicalReviewStatusChild(data),
      [],
    ),
    getFacilitiesForClinicalReview: useCallback(
      (patientId: number) => getFacilitiesForClinicalReview(patientId),
      [],
    ),
    getAllRoutes: useCallback(() => getRoutes(), []),
    setRouteList: useCallback(list => {
      dispatch(referralActions.setRouteList(list))
    }, []),
    getRouteList: useAppSelector(getRouteList),
    setOrganizationList: useCallback(list => {
      dispatch(referralActions.setOrganizationList(list))
    }, []),
    organizationList: useAppSelector(getOrganizationList),

    getAllReferralStatus: useCallback((permission: number[]) => getReferralStatus(permission), []),
    referralListRoot: useAppSelector(getReferralListRoot),
    setReferralListRoot: useCallback(type => {
      dispatch(referralActions.setReferralListRoot(type))
    }, []),
    getIntegrationFeatures: useCallback((id: number) => getIntegrationFeatures(id), []),
    sendOutBoundCommunication: useCallback(
      (data: OutboundMessageRequest) => sendOutBoundCommunication(data),
      [],
    ),
    getMessagesFromSource: useCallback(requestBody => getMessagesFromSource(requestBody), []),
    getRefreshForSourceMessageData: useAppSelector(getRefreshForSourceMessageData),
    setRefreshForSourceMessageData: useCallback(data => {
      dispatch(referralActions.setRefreshForSourceMessageData(data))
    }, []),
    reviseChildReferralStatus: useCallback(
      (patientId: number, statusId: number) => childReferralStatusRevise(patientId, statusId),
      [],
    ),
    reviseToClinicalReview: useCallback(
      (patientId: number, statusId: number, note: string) =>
        callReviseToClinicalReview(patientId, statusId, note),
      [],
    ),
    getSourceMessageTabToSet: useAppSelector(getSourceMessageTabToSet),
    setSourceMessageTabToSet: useCallback(tabVal => {
      dispatch(referralActions.setSourceMessageTabToSet(tabVal))
    }, []),

    getTodoList: useCallback(data => getTodoList(data), []),
    updateTodoItem: useCallback(data => updateTaskListStatus(data), []),
    getReferralListInputs: useAppSelector(getReferralListInputs),
    setReferralListInputs: useCallback((type, data) => {
      dispatch(referralActions.setReferralListInputs({ type, data }))
    }, []),
    getPccPatientDetails: useCallback(
      (orgUuid: string, patientId: number) => getPccPatientDetails(orgUuid, patientId),
      [],
    ),

    getRecentDenialReason: useCallback(data => getMostRecentDenialReason(data), []),
    getIsTaskListPending: useAppSelector(getIsTaskListPending),
    setIsTaskListPending: useCallback(data => {
      dispatch(referralActions.setIsTaskListPending(data))
    }, []),
    getIntegrationSourceStatus: useCallback((id: number) => getIntegrationSourceStatus(id), []),
    getInsuranceDetailsByPatientId: useCallback(
      (data: InsuranceDetailReq) => getInsuranceDetailsByPatientId(data),
      [],
    ),
    setWaystarValidationChanged: useCallback(() => {
      dispatch(referralActions.setWaystarValidationChanged())
    }, []),
    setPVerifyValidationChanged: useCallback(() => {
      dispatch(referralActions.setPVerifyValidationChanged())
    }, []),
    waystarValidationChanged: useAppSelector(waystarValidationChanged),
    pVerifyValidationChanged: useAppSelector(pVerifyValidationChanged),

    getHoldReasons: useCallback(() => callHoldReasons(), []),
    setHoldReasons: useCallback(list => {
      dispatch(referralActions.setHoldReasons(list))
    }, []),
    holdReasons: useAppSelector(getHoldReasons),

    getLostReasons: useCallback(() => callLostReasons(), []),
    setLostReasons: useCallback(list => {
      dispatch(referralActions.setLostReasons(list))
    }, []),
    lostReasons: useAppSelector(getLostReasons),
    unlockReferral: useCallback((data: UnLockReferralData) => unLockReferral(data), []),
    unmappedCommunitiesList: useCallback((data: string) => getUnmappedCommunities(data), []),
    mappedCommunitiesList: useCallback(
      (data: MappedCommunitiesRequest) => getMappedCommunities(data),
      [],
    ),
    getCommunitiesForUnmatched: useCallback(
      (data: CommunitiesForUnmatchedRequest) => getCommunitiesForUnmatched(data),
      [],
    ),
    addMappedCommunity: useCallback(
      (data: AddMappedCommunityRequest) => addMappedCommunity(data),
      [],
    ),
    updateMappedCommunity: useCallback(
      (data: AddMappedCommunityRequest) => updateMappedCommunity(data),
      [],
    ),
    regeneratePDF: useCallback((data: { patientId: number }) => referralRegeneratePDF(data), []),
    setDenialReasonList: useCallback(list => {
      dispatch(referralActions.setDenialReasonList(list))
    }, []),
    denialReasonList: useAppSelector(getDenialReasonList),
    setReferralGridViewList: useCallback(list => {
      dispatch(referralActions.setReferralGridViewList(list))
    }, []),
    referralGridViewList: useAppSelector(getReferralGridViewList),

    setFacilityListForParentFilter: useCallback(list => {
      dispatch(referralActions.setFacilityListForParentFilter(list))
    }, []),
    facilityListForParentFilter: useAppSelector(getFacilityListForParentFilter),
    setFacilityListForChildFilter: useCallback(list => {
      dispatch(referralActions.setFacilityListForChildFilter(list))
    }, []),
    facilityListForChildFilter: useAppSelector(getFacilityListForChildFilter),
    insuranceProvider: useAppSelector(getInsuranceProvider),
    setInsuranceProvider: useCallback((type: number) => {
      dispatch(referralActions.setInsuranceProvider(type))
    }, []),
    pVerifyLinks: useAppSelector(getPVerifyLinks),
    setPVerifyLinks: useCallback((data: PVerifyLinks) => {
      dispatch(referralActions.setPVerifyLinks(data))
    }, []),
    getPVerifyPayerList: useCallback(() => getPverifyPayerLists(), []),
    pVerifyMatchRequest: useCallback(body => pVerifyMatchRequest(body), []),
    pVerifySubmit: useCallback((data: PVerifySubmitObject) => pVerifySubmit(data), []),
    pVerifyValidate: useCallback(body => pVerifyValidate(body), []),
    pVerifyPayerDelete: useCallback(body => pVerifyDeletePayer(body), []),
    getAssignUsersList: useCallback((data: AssignLeads) => fetchAssignUsersList(data), []),
    fetchAssignedUsersList: useCallback((data: AssignedUsersReq) => assignedUsersList(data), []),
    deleteAssignedRefUser: useCallback((data: DeleteAssignedUserReq) => deleteLeadAgent(data), []),
    addReferralUsers: useCallback((data: AddUsersReq) => addReferralUsers(data), []),
    getAssignedUsersCount: useCallback((data: number) => assignedUsersCount(data), []),
    confirmReadAlert: useCallback((data: ConfirmReadAlertRequest) => confirmReadAlert(data), []),
    getReferralUpdates: useCallback((data: number) => fetchReferralUpdates(data), []),
    setReferralComparisonId: useCallback(data => {
      dispatch(referralActions.setReferralComparisonId(data))
    }, []),
    getSourceHospitalList: useCallback(() => getAllSourceHospitals(), []),
  }
}

export default useReferralsService
